import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IconLink,
  LogoLink,
  NavigationLink,
  ProfileLink,
  Translate,
  SettingMenuDropdown,
  Notification,
  ReleaseMessage,
} from 'components';
import { AvailabilityNowContainer, NotificationContainer } from 'containers';
import './style.scss';

const TopNavBar = props => {
  const { customerName, onLogout } = props;
  const dropMenuNL = useRef(null);
  const dropMenuEL = useRef(null);
  return (
    <div className="tv-topnavbar__container d-sm-none d-md-block d-none d-sm-block">
      <div className="d-flex tv-topnavbar__flex">
        <div className="mr-auto mr-5">
          <LogoLink />
        </div>
        <ReleaseMessage />

        <div className="mr-4">
          <AvailabilityNowContainer />
        </div>
        <div>
          <div className="navigation tv-navigationlink">
            <div className="navbar-expand">
              <div className="navbar-nav justify-content-end">
                <NavigationLink
                  exact
                  to="/"
                  className="nav-item"
                  text={Translate({ content: 'navigation.calendar' })}
                />
                <NavigationLink
                  to="/my-assignments"
                  className="nav-item"
                  text={Translate({
                    content: 'navigation.assignmentAndInquiries',
                  })}
                />
                <NavigationLink
                  to="/contact-and-faq"
                  className="nav-item"
                  text={Translate({ content: 'navigation.contact' })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <NotificationContainer
            key="NotificationContainerMenu"
            className={{ iconClass: 'tv-topnavbar__noti-icon' }}
            innerRef={dropMenuNL}
          />
        </div>
        <div>
          <SettingMenuDropdown button={<ProfileLink />} innerRef={dropMenuEL}>
            <div className="tv-avatar-list__menu">
              <div
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-name"
                tabIndex="-1"
              >
                {`Hej ${customerName || 'Användare'}`}
              </div>

              <NavigationLink
                to="/settings"
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
                text="Min profil"
              />
              <NavigationLink
                to="/releases-page"
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
                text="Nyheter"
              />
              <NavigationLink
                onClick={onLogout}
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--logout"
                text="Logga ut"
              />
            </div>
          </SettingMenuDropdown>
        </div>
      </div>
    </div>
  );
};

TopNavBar.propTypes = {
  customerName: PropTypes.string,
  onLogout: PropTypes.func,
};

TopNavBar.defaultProps = {
  customerName: '',
  onLogout: () => {},
};

export default TopNavBar;
