import React from 'react';
import PropTypes from 'prop-types';
import { Button, AvailablitySlot, Translate } from 'components';

import './style.scss';

function DefaultAvailablity({
  onCopyTimeToAll,
  onAddAvailability,
  onDelete,
  onChange,
  onSave,
  data,
}) {
  return (
    <div className="tv-default-availablity__container">
      <h4 className="tv-default-availablity__title">
        {Translate({ content: 'defaultAvailablity.title' })}
      </h4>
      <p className="tv-default-availablity__content">
        Här hanterar du din Schemalagda tillgänglighet. Schemalagd
        tillgänglighet passar dig som jobbar i större utsträckning för
        Transvoice. Du lägger in den tid du önskar jobba en eller flera dagar i
        veckan, alltså den tid som du är tillgänglig för uppdrag. Exempelvis
        mån-fre 07:00-18:00. Den tid du lägger in här gäller sedan tillsvidare
        som din Schemalagda tillgänglighet. Kortare pass där du är Upptagen
        hanterar du sedan i Tillgänglig/Upptagen.
      </p>
      <div className="tv-default-availablity__wrapper">
        <h4 className="tv-default-availablity__title-2">
          {Translate({ content: 'defaultAvailablity.question' })}
        </h4>
        <div className="tv-default-availablity__time-slots">
          <div className="tv-default-availablity__label">
            <span className="tv-default-availablity__label-from">
              {Translate({ content: 'defaultAvailablity.from' })}
            </span>
            <span className="tv-default-availablity__label-to">
              {Translate({ content: 'defaultAvailablity.to' })}
            </span>
          </div>

          {data.map(item => (
            <AvailablitySlot
              key={item.keyId}
              onChange={onChange}
              onDelete={day => onDelete(day, item.SettingIdentifier)}
              onAddAvailability={onAddAvailability}
              dayName={item.name}
              slotStart={item.startTime}
              slotEnd={item.endTime}
              showCopyTimeToAll={item.showCopyTimeToAll}
              onCopyTimeToAll={onCopyTimeToAll}
              text={item.text}
              state={item.state}
            />
          ))}
        </div>
      </div>
      <div className="tv-default-availablity__button-wrapper">
        <Button
          onClick={onSave}
          label={Translate({ content: 'defaultAvailablity.save' })}
          className="tv-default-availablity__save"
        />
      </div>
    </div>
  );
}

DefaultAvailablity.propTypes = {
  onCopyTimeToAll: PropTypes.func,
  onAddAvailability: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
DefaultAvailablity.defaultProps = {
  onCopyTimeToAll: () => {},
  onAddAvailability: () => {},
  onDelete: () => {},
  onChange: () => {},
  onSave: () => {},
  data: [],
};

export default DefaultAvailablity;
