/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Translate,
  DatePicker,
  TabButtonRow,
  TimePicker,
  Button,
} from 'components';
import './style.scss';
import { localDayjs } from 'utils/dateTimeUtils';
import { NavLink } from 'react-router-dom';

const tabs = [
  { name: 'available', label: 'Tillgänglig' },
  { name: 'occupied', label: 'Upptagen' },
];

function hasCurrentDaySelected(selectedDate) {
  const returnValue = localDayjs(selectedDate).isSame(localDayjs(), 'day');
  return returnValue;
}
function ExceptionAvailabilityModal({
  onClose,
  onDatePickerChange,
  onTimePickerChange,
  onAvailablityTypeChange,
  onDelete,
  onConfirm,
  isVisible,
  selectedDate,
  startTime,
  endTime,
  exceptionType,
  validationError,
  showDelete,
}) {
  return (
    <Modal
      mainTitle={Translate({ content: 'exception.header' })}
      isVisible={isVisible}
      className="tv-exception-avilability__container"
      onClose={onClose}
    >
      <div className="tv-exception-avilability__content">
        <div className="tv-exception-avilability__day-availability">
          <p>
            <span> Här kan du lägga dig Tillgänglig eller Upptagen.</span>
            <br />
            <br />
            <span>
              För att hantera din schemalagda tillgänglighet klicka
              <NavLink to="/settings/#default-availability">
                <span className="tv-exception-avilability__link">Här</span>
              </NavLink>
            </span>
            <br />
            <span>
              För att ange semester eller längre ledighet klicka
              <NavLink to="/settings/#semesters-allocation">
                <span className="tv-exception-avilability__link">Här</span>
              </NavLink>
            </span>
          </p>
        </div>
        <hr className="tv-exception-avilability__seperator" />
        <div className="tv-exception-avilability__wrapper">
          <div className="tv-exception-avilability__tab-container">
            <TabButtonRow
              className="tab"
              tabs={tabs}
              activeTab={exceptionType}
              onTabClick={onAvailablityTypeChange}
            />
          </div>
          <div className="tv-exception-avilability__datepicker-container">
            <div className="tv-exception-avilability__control">
              <label htmlFor="datenum">Datum</label>
              <DatePicker
                selectedDate={selectedDate}
                minDate={new Date()}
                label="Datum"
                name="Datum"
                placeholderText="Önskat datum"
                onChange={onDatePickerChange}
              />
            </div>
            <div className="tv-exception-avilability__control-time">
              <div className="tv-exception-avilability__control ">
                <label htmlFor="Start">Start</label>
                <TimePicker
                  label="Start"
                  inputName="Start"
                  startFromDayBegin={!hasCurrentDaySelected(selectedDate)}
                  minuteStep={15}
                  defaultValue={startTime}
                  onChange={timeObj => onTimePickerChange(timeObj, 'start')}
                />
              </div>
              <div className="tv-exception-avilability__control">
                <label htmlFor="Stopp">Stopp</label>
                <TimePicker
                  label="Stopp"
                  inputName="Stopp"
                  startFromDayBegin={!hasCurrentDaySelected(selectedDate)}
                  defaultValue={endTime}
                  minuteStep={15}
                  onChange={timeObj => onTimePickerChange(timeObj, 'end')}
                />
              </div>
            </div>
          </div>
        </div>
        {!validationError.isValid && (
          <div className="tv-exception-avilability__errors">
            {validationError.errorMessage}
          </div>
        )}
        <div className="tv-exception-avilability__actions">
          {showDelete && (
            <div className="tv-exception-avilability__actions__row">
              <div
                className="tv-exception-avilability__actions--delete"
                onClick={onDelete}
              >
                Ta bort
              </div>
            </div>
          )}
          <div className="tv-exception-avilability__actions__row">
            <Button
              label="Avbryt"
              className="tv-exception-avilability__actions-button"
              palette="outline"
              onClick={onClose}
            />
            <Button
              label="Lägg till"
              className="tv-exception-avilability__actions-button"
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

ExceptionAvailabilityModal.propTypes = {
  onClose: PropTypes.func,
  onDatePickerChange: PropTypes.func,
  onTimePickerChange: PropTypes.func,
  onAvailablityTypeChange: PropTypes.func,
  onDelete: PropTypes.func,

  onConfirm: PropTypes.func,
  isVisible: PropTypes.bool,
  selectedDate: PropTypes.shape({}),
  startTime: PropTypes.shape({}),
  endTime: PropTypes.shape({}),
  exceptionType: PropTypes.string,
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  showDelete: PropTypes.bool,
};

ExceptionAvailabilityModal.defaultProps = {
  onClose: () => {},
  onDatePickerChange: () => {},
  onTimePickerChange: () => {},
  onAvailablityTypeChange: () => {},
  onConfirm: () => {},
  onDelete: () => {},
  isVisible: false,
  selectedDate: null,
  startTime: new Date(),
  endTime: new Date(),
  exceptionType: 'occupied',
  validationError: {
    isValid: true,
    errorMessage: '',
  },
  showDelete: false,
};

export default ExceptionAvailabilityModal;
