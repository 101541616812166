/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { calculateHeightAndPosition } from 'context/CalendarContext';
import { convertToDayJsObject } from 'utils/dateTimeUtils';

export default function AvailabilityList({
  list = [],
  listClassName,
  itemClassName,
  onItmeclick,
}) {
  const [availabilityList, setAvailabilityList] = useState(list || []);
  useEffect(() => {
    setAvailabilityList(list);
  }, [list]);

  return (
    <div className="listClassName tv-week-day-availability__container">
      {availabilityList.map(availability => (
        <AvailabilitySlot
          detail={availability}
          className={itemClassName}
          onClick={item => {
            onItmeclick(availability);
          }}
          key={`week-availability-${convertToDayJsObject(
            availability.DatetimeFrom,
          ).format('YYYYMMDDHH:mm')}`}
        />
      ))}
    </div>
  );
}

function AvailabilitySlot({ onClick, className, detail }) {
  const [timeFrom, timeTo] = [
    convertToDayJsObject(detail.DatetimeFrom),
    convertToDayJsObject(detail.DatetimeTo),
  ];
  const processItem = calculateHeightAndPosition(timeFrom, timeTo);
  const timeRangeString = `${timeFrom.format('HH:mm')} - ${timeTo.format(
    'HH:mm',
  )}`;
  return (
    <>
      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={`tv-week-day-availability__slot ${
          detail.AvailabilityType === 4 &&
          'tv-week-day-availability__slot-available-now'
        } `}
        style={{
          top: processItem.yCoordinate,
          height: processItem.height,
          background: detail.AvailabilityType === 2 ? '#FFF' : '#F2F2F2',
          zIndex: detail.AvailabilityType === 3 && '4',
        }}
      >
        {detail.DefaultAvailabilityExceptionIdentifier && (
          <div
            className="tv-week-view-assignment-item__container tv-week-view-assignment-item__container--availablity tv-week-view-assignment-item--availablity"
            role="button"
            tabIndex="0"
            onClick={() => onClick(detail)}
            onKeyPress={() => onClick(detail)}
          >
            <div className="tv-week-view-assignment-item__header">
              <div className="tv-week-view-assignment-item__header__name">
                {detail.AvailabilityType === 2 ? 'Tillgänglig' : 'Upptagen'}
              </div>
            </div>
            {processItem.diffInMinutes > 15 && (
              <div className="tv-week-view-assignment-item__sub-container">
                <div className="tv-week-view-assignment-item__sub-item">
                  <span className="tv-week-view-assignment-item__sub-item__value--text">
                    {timeRangeString}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        {detail.AvailabilityType === 4 && (
          <div className="tv-week-view-available-now__container">
            TolkaDirekt-tillgänglig till {timeTo.format('HH:mm')}
          </div>
        )}
        <div className="arrow-right" />
        <div className="arrow-left" />
      </div>
    </>
  );
}
