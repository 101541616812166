/* eslint-disable no-else-return */
import { useState, useContext, useEffect } from 'react';
import { ReactReduxContext, useSelector } from 'react-redux';
import {
  resourceListReadRequest,
  resourceDetailReadRequest,
  resourceListReadSuccess,
  resourceCreateRequest,
} from 'store/actions';
import { fromResource, fromWorkAssignment } from 'store/selectors';
import { extendedDayJs } from 'utils/dateTimeUtils';
import { pending, fulfilled, clean } from 'redux-saga-thunk';

const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const selectDateRange = (
  initial,
  dateRangeFromResource,
  getState,
  resource,
) => {
  if (!initial && dateRangeFromResource && dateRangeFromResource.start) {
    return {
      DatetimeFrom: extendedDayJs(dateRangeFromResource.start).format(
        STANDARD_DATE_TIME_FORMAT,
      ),
      DatetimeTo: extendedDayJs(dateRangeFromResource.end).format(
        STANDARD_DATE_TIME_FORMAT,
      ),
    };
  } else {
    const dateRange = fromWorkAssignment.getWorkAssignmentResourcesDateRange(
      getState(),
      resource.includes('calendar') ? 'calendar' : 'assignmentList',
    );
    return {
      DatetimeFrom: extendedDayJs(dateRange.start).format(
        STANDARD_DATE_TIME_FORMAT,
      ),
      DatetimeTo: extendedDayJs(dateRange.end).format(
        STANDARD_DATE_TIME_FORMAT,
      ),
    };
  }
};

// eslint-disable-next-line no-unused-vars
const isRequestPreviouslyMade = (resource, params, getState) => true;

const useWorkAssignment = (resource = 'calendarAssignment') => {
  const { store } = useContext(ReactReduxContext);
  const { getState, dispatch } = store;

  const ratedWorkAssignmentList = useSelector(state =>
    fromResource.getDetail(state, 'ratedWorkAssignments'),
  );

  const resourceAvailability = useSelector(state =>
    fromResource.getList(state, 'resourceAvailability'),
  );
  const assignmentRequestList = useSelector(state =>
    fromResource.getList(state, 'assignmentRequest'),
  );
  const newNotifications = useSelector(state =>
    fromResource.getList(state, 'allNotifications'),
  );
  const workAssignmentList = useSelector(state =>
    fromResource.getList(state, resource),
  );

  const semesterAllocations = useSelector(state =>
    fromResource.getList(state, 'semesterAllocations'),
  );

  const workAssignment = useSelector(state => {
    const resultList = fromResource.getList(
      state,
      'searchSingleWorkAssignment',
    );
    return resultList ? resultList[0] : null;
  });

  const workAssignmentPending = useSelector(state =>
    pending(state, `${resource}ListRead`),
  );
  const assignmentRequestResponse = useSelector(state =>
    fromResource.getList(state, 'replyToResourceRequest'),
  );

  const assignmentRequestFulfilled = useSelector(state =>
    fulfilled(state, 'replyToResourceRequestCreate'),
  );

  const singleAssignmentRetriveFulfilled = useSelector(state =>
    fulfilled(state, 'searchSingleWorkAssignmentListRead'),
  );
  const singleAssignmentRetrivePending = useSelector(state =>
    pending(state, 'searchSingleWorkAssignmentListRead'),
  );
  const assignmentRequestPending = useSelector(state =>
    pending(state, 'replyToResourceRequestCreate'),
  );
  const searchAllNewNotificationsPending = useSelector(state =>
    pending(state, 'searchAllNotificationsDetailRead'),
  );
  const notificationReadStatusFulfilled = useSelector(state =>
    fulfilled(state, 'SetNotificationReadStatusCreate'),
  );

  const cleanReplyToRequest = () => {
    dispatch(clean(`replyToResourceRequestCreate`));
  };

  const replyToAssignmentRequest = params => {
    dispatch(resourceCreateRequest('replyToResourceRequest', params));
  };

  // eslint-disable-next-line no-unused-vars
  const currentSearchDateRange = () =>
    fromWorkAssignment.getWorkAssignmentResourcesDateRange(
      getState(),
      resource.includes('calendar') ? 'calendar' : 'assignmentList',
    );

  useEffect(() => {
    if (singleAssignmentRetriveFulfilled) {
      const renewWorkAssignmentList = workAssignmentList.find(
        x =>
          x.WorkAssignmentIdentifier ===
          workAssignment?.WorkAssignmentIdentifier,
      )
        ? workAssignmentList.map(x =>
            x.WorkAssignmentIdentifier ===
            workAssignment.WorkAssignmentIdentifier
              ? workAssignment
              : x,
          )
        : [...workAssignmentList, workAssignment];

      dispatch(
        resourceListReadSuccess(
          resource,
          'WorkAssignments',
          {
            WorkAssignments: renewWorkAssignmentList,
          },
          null,
          null,
          true,
        ),
      );

      dispatch(clean('searchSingleWorkAssignmentListRead'));
    }
  }, [singleAssignmentRetriveFulfilled]);

  useEffect(() => {}, [assignmentRequestFulfilled]);

  const removeRequestReplied = requestAssignmentIdentifier => {
    if (requestAssignmentIdentifier) {
      const renewRequestAssignmentList = assignmentRequestList.filter(
        x => x.ResourceRequestIdentifier !== requestAssignmentIdentifier,
      );
      dispatch(
        resourceListReadSuccess(
          'assignmentRequest',
          'ResourceRequests',
          {
            ResourceRequests: renewRequestAssignmentList,
          },
          null,
          null,
          true,
        ),
      );
    }
  };

  const searchWorkAssignment = (
    initial = false,
    dateRangeFromResource,
    isRefresh = false,
    searchText = null,
    assignmentIdentifier = null,
  ) => {
    const dateRange = selectDateRange(
      initial,
      dateRangeFromResource,
      getState,
      resource,
    );
    const params = {
      ...dateRange,
      Keywords: searchText,
      WorkAssignmentIdentifier: assignmentIdentifier,
    };
    if (initial || !isRequestPreviouslyMade(resource, params, getState)) {
      // update store date range
    }
    if (assignmentIdentifier) {
      dispatch(
        resourceListReadRequest(
          'searchSingleWorkAssignment',
          'SingleAssignments',
          params,
          isRefresh,
        ),
      );
    } else {
      dispatch(
        resourceListReadRequest(resource, 'WorkAssignments', params, isRefresh),
      );
    }
  };

  const searchSingleWorkAssignment = (assignmentIdentifier = null) => {
    if (assignmentIdentifier) {
      const params = { WorkAssignmentIdentifier: assignmentIdentifier };
      dispatch(
        resourceListReadRequest(
          'searchSingleWorkAssignment',
          'WorkAssignments',
          params,
          true,
        ),
      );
    }
  };

  const getResourceAvailability = (
    dateRangeFromResource,
    isRefresh = false,
  ) => {
    const params = {
      DatetimeFrom: extendedDayJs(dateRangeFromResource.start).format(
        STANDARD_DATE_TIME_FORMAT,
      ),
      DatetimeTo: extendedDayJs(dateRangeFromResource.end).format(
        STANDARD_DATE_TIME_FORMAT,
      ),
    };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        isRefresh,
      ),
    );
  };

  const getInterpreterRequests = () => {
    dispatch(
      resourceListReadRequest('assignmentRequest', 'ResourceRequests', {
        ResourceRequestStatus: 2,
      }),
      true,
    );
  };

  const getAllNotifications = () => {
    dispatch(
      resourceListReadRequest('allNewNotifications', 'NotificationsNew', {
        DatetimeSentFrom: null,
        DoOnlyIncludeUnreadNotifications: true,
        OrderIdentifier: null,
        WorkAssignmentIdentifier: null,
      }),
      true,
    );
  };

  const setNotificationStatus = NotificationIdentifier => {
    dispatch(
      resourceCreateRequest('SetNotificationReadStatus', {
        IsRead: true,
        NotificationIdentifier,
      }),
    );
  };

  return {
    workAssignmentList,
    workAssignment: workAssignment || {},
    assignmentRequestList,
    resourceAvailability,
    semesterAllocations,
    searchWorkAssignment,
    searchSingleWorkAssignment,
    getResourceAvailability,
    getInterpreterRequests,
    replyToAssignmentRequest,
    cleanReplyToRequest,
    removeRequestReplied,
    singleAssignmentRetriveFulfilled,
    singleAssignmentRetrivePending,
    assignmentRequestResponse,
    assignmentRequestFulfilled,
    getAllNotifications,
    setNotificationStatus,
    notificationReadStatusFulfilled,
    newNotifications,
    isLoading:
      workAssignmentPending ||
      singleAssignmentRetrivePending ||
      assignmentRequestPending ||
      searchAllNewNotificationsPending,
    isLoadingAssignmentRequests: assignmentRequestPending,
    ratedWorkAssignmentList,
  };
};

export default useWorkAssignment;
