import { PIXEL_PER_HOUR, status } from 'constant';
import { createContext, useContext } from 'react';
import {
  convertToDayJsObject,
  currentDate,
  isDayWithinTheRange,
  isSameDay,
} from 'utils/dateTimeUtils';

import { clone } from 'lodash';

import dayjs from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const CalendarContext = createContext();

export default CalendarContext;

// Calendar Context consuming hook
export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error(
      'useCalendar must be used within a CalendarContext.Provider',
    );
  }
  return context;
};

// Calendar related Constants

export const CALENDAR_VIEW = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
};

const availabiltyFilterOptions = [
  { displayText: 'Alla', isActive: true, key: 'all', type: 0 },

  { displayText: 'Otillgänglig', isActive: true, key: 'occupied', type: 3 },
  { displayText: 'Tillgänglig', isActive: true, key: 'available', type: 2 },
  {
    displayText: 'Tillgänglig Nu',
    isActive: true,
    key: 'availableNow',
    type: 4,
  },
];

const INITIAL_STATUS_FILTER = [
  status.reported.name,
  status.accept.name,
  status.performed.name,
  status.cancelled.name,
  status.inquiry.name,
];
// Calendar Related helper function

export const generateCalendarDateRange = tab => {
  const modifiedTab = tab === 'week' ? 'isoWeek' : tab;
  const dateRange = {
    start: currentDate().startOf('day').startOf(modifiedTab),
    startMonth: currentDate().startOf('day').startOf(modifiedTab),
    end: currentDate().startOf('day').endOf(modifiedTab),
    highlightedDay: currentDate(),
  };
  if (tab === 'month') {
    dateRange.start = dateRange.start.startOf('isoWeek');
    dateRange.end = dateRange.end.endOf('isoWeek');
  }
  return dateRange;
};

export const shiftCalendarDateRange = (direction, tab, calendarDateRange) => {
  let { startMonth, start, end, highlightedDay } = calendarDateRange;
  if (tab === CALENDAR_VIEW.MONTH) {
    if (direction === 'forward') {
      startMonth = startMonth.add(1, CALENDAR_VIEW.MONTH);
    } else {
      startMonth = startMonth.subtract(1, CALENDAR_VIEW.MONTH);
    }
    end = startMonth.endOf(CALENDAR_VIEW.MONTH).endOf('isoWeek');
    start = startMonth.startOf('isoWeek');
  }

  if (tab === CALENDAR_VIEW.WEEK) {
    if (direction === 'forward') {
      start = start.add(1, CALENDAR_VIEW.WEEK);
    } else {
      start = start.subtract(1, CALENDAR_VIEW.WEEK);
    }
    end = start.endOf('isoWeek');
    startMonth = start.startOf(CALENDAR_VIEW.MONTH);
  }

  if (tab === CALENDAR_VIEW.DAY) {
    if (direction === 'forward') {
      start = start.add(1, CALENDAR_VIEW.DAY);
    } else {
      start = start.subtract(1, CALENDAR_VIEW.DAY);
    }
    end = clone(start);
    startMonth = start.startOf(CALENDAR_VIEW.MONTH);
  }

  highlightedDay = startMonth.isSame(start, 'month')
    ? start
    : startMonth.startOf('month');

  return {
    start,
    end,
    startMonth,
    highlightedDay,
  };
};

export const filterEventsForTheDateRange = (
  list = [],
  startDateTime,
  endtDateTime,
) => {
  const eventsInTheDateRange = list.filter(event => {
    const eventStartDateTime = convertToDayJsObject(event.DatetimeOrderFrom);
    return isDayWithinTheRange(eventStartDateTime, startDateTime, endtDateTime);
  });
  return eventsInTheDateRange;
};

export const filterAssignmnentsdBasedOnStatus = (
  filters,
  assignmentList = [],
  requestList = [],
) => {
  let originalStatus = [];
  let localAssignmentList = [];
  if (filters[0] === 'all-assignments') {
    localAssignmentList = [];
  } else {
    originalStatus = filters.map(x => status.mapToOriginalStatus(x));
    localAssignmentList = assignmentList.filter(
      x => originalStatus.indexOf(x.status) > -1,
    );
  }
  return {
    assignmentList: localAssignmentList || [],
    requestList: originalStatus.indexOf('inquiry') > -1 ? requestList : [],
  };
};

export const filterAvailabilityPerDay = (list = [], date) => {
  const availabilitiesInTheDateRange = list.filter(availability => {
    const availabilitiestartDateTime = convertToDayJsObject(
      availability.DatetimeFrom,
    );
    return isDayWithinTheRange(
      availabilitiestartDateTime,
      date.startOf('day'),
      date.endOf('day'),
    );
  });
  return availabilitiesInTheDateRange;
};

export const extractSemesterDay = (list = [], date) => {
  const showSemesterDay =
    dayjs(dayjs(date).format('YYYY-MM-DD')).diff(
      dayjs().format('YYYY-MM-DD', 'd'),
    ) >= 0;

  const extractedSemesterItem = list.find(
    item =>
      isSameDay(date, item.DateVacation) &&
      (showSemesterDay ||
        dayjs(dayjs().format('YYYY-MM-DD')).isBetween(
          item.FirstDayOfVacationPeriod,
          item.LastDayOfVacationPeriod,
          'd',
          '[]',
        )),
  );
  if (extractedSemesterItem) {
    return {
      isSemesterDay: true,
      isSemesterStartDay: isSameDay(
        date,
        extractedSemesterItem.FirstDayOfVacationPeriod,
      ),
      data: extractedSemesterItem,
    };
  }
  return {
    isSemesterDay: false,
    data: null,
  };
};

export const sortAssignmentRequestList = (
  workAssignmentList = [],
  requestList = [],
  sortedAsc = false,
) => {
  const data = [...workAssignmentList, ...requestList];
  const shouldSortList = requestList.length > 0;
  let sortedAssignmentRequestList = data;
  if (shouldSortList) {
    sortedAssignmentRequestList = data.sort((a, b) => {
      if (sortedAsc) {
        return convertToDayJsObject(b.DatetimeOrderFrom).diff(
          a.DatetimeOrderFrom,
        );
      }
      return convertToDayJsObject(a.DatetimeOrderFrom).diff(
        b.DatetimeOrderFrom,
      );
    });
  }

  return sortedAssignmentRequestList;
};

export const scheduleAvailabilityBasedOnFilters = (
  schedulevAvailability,
  filterOptions,
) =>
  schedulevAvailability.filter(x => {
    if (x.AvailabilityType === filterOptions[1].type) {
      return filterOptions[1].isActive;
    }
    if (x.AvailabilityType === filterOptions[2].type) {
      return filterOptions[2].isActive;
    }
    if (x.AvailabilityType === filterOptions[3].type) {
      return filterOptions[2].isActive;
    }
    return false;
  });

export const calculateHeightAndPosition = (timeFrom, timeTo) => {
  const diffFromDaySatrt = timeFrom.diff(timeFrom.startOf('day'), 'minutes');
  const diffInMinutes = timeTo.diff(timeFrom, 'minutes') || 0;
  const fifteenMinPortions = diffInMinutes / 15;
  const fullHourhieghtInPx = PIXEL_PER_HOUR;
  return {
    height: `${(fullHourhieghtInPx * fifteenMinPortions) / 4}px`,
    yCoordinate: `${(fullHourhieghtInPx * diffFromDaySatrt) / 60}px`,
    diffInMinutes,
  };
};
// Calendar Reducer
export const calendarInitialState = {
  searchDateRange: {
    start: currentDate()
      .startOf(CALENDAR_VIEW.MONTH)
      .subtract(1, CALENDAR_VIEW.MONTH),
    end: currentDate().endOf(CALENDAR_VIEW.MONTH).add(1, CALENDAR_VIEW.MONTH),
  },
  selectedCalendarTab:
    window.innerWidth > 786 ? CALENDAR_VIEW.WEEK : CALENDAR_VIEW.DAY,
  selectedCalendarMonthPill: null,
  calendarDateRange: generateCalendarDateRange(
    window.innerWidth > 786 ? CALENDAR_VIEW.WEEK : CALENDAR_VIEW.DAY,
  ),
  visibleWorkAssignment: [],
  visibleAssignmentRequestList: [],
  visibleSchedule: [],
  visibleSemesterAllocations: [],
  selectedStatusFilter: INITIAL_STATUS_FILTER,
  availabiltyFilterOptions,
};

export const calendarReducer = (state, [type, payload]) => {
  switch (type) {
    case 'setSearchDateRange':
      return {
        ...state,
        searchDateRange: payload,
      };
    case 'setselectedCalendarTab':
      return {
        ...state,
        selectedCalendarTab: payload,
      };
    case 'setSelectedCalendarMonthPill':
      return {
        ...state,
        selectedCalendarMonthPill: payload,
      };
    case 'setCalendarDateRange': {
      const { visibleWorkAssignment, calendarDateRange } = payload;
      const { assignmentList } = filterAssignmnentsdBasedOnStatus(
        state.selectedStatusFilter,
        visibleWorkAssignment,
      );
      return {
        ...state,
        visibleWorkAssignment: assignmentList,
        sortedAssignmentList: sortAssignmentRequestList(
          assignmentList,
          state.visibleAssignmentRequestList,
        ),
        calendarDateRange,
      };
    }
    case 'setVisibleWorkAssignment': {
      const { assignmentList } = filterAssignmnentsdBasedOnStatus(
        state.selectedStatusFilter,
        payload,
      );
      return {
        ...state,
        visibleWorkAssignment: assignmentList,
        sortedAssignmentList: sortAssignmentRequestList(
          state.visibleAssignmentRequestList,
          assignmentList,
        ),
      };
    }
    case 'setAvailabilityFilters':
      return {
        ...state,
        availabiltyFilterOptions: payload.options,
        visibleSchedule: payload.filteredList,
      };
    case 'setVisibleAssignmentRequestList':
      return {
        ...state,
        visibleAssignmentRequestList: payload,
        sortedAssignmentList: sortAssignmentRequestList(
          state.visibleWorkAssignment,
          payload,
        ),
      };
    case 'setVisibleSchedule':
      return {
        ...state,
        visibleSchedule: payload,
      };
    case 'setVisibleSemesterAllocations':
      return {
        ...state,
        visibleSemesterAllocations: payload,
      };
    case 'setStatusFilter': {
      const { filters, assignmentList, requestList } = payload;
      return {
        ...state,
        selectedStatusFilter: filters,
        visibleAssignmentRequestList: requestList,
        visibleWorkAssignment: assignmentList,
        sortedAssignmentList: sortAssignmentRequestList(
          requestList,
          assignmentList,
        ),
      };
    }
    case 'setAllFilters': {
      const {
        statusFilter,
        availabilityFilters,
        assignmentList,
        requestList,
        filteredAvailabilitySchedule,
      } = payload;
      return {
        ...state,
        selectedStatusFilter: statusFilter,
        availabiltyFilterOptions: availabilityFilters,
        visibleSchedule: filteredAvailabilitySchedule,
        visibleAssignmentRequestList: requestList,
        visibleWorkAssignment: assignmentList,
        sortedAssignmentList: sortAssignmentRequestList(
          assignmentList,
          requestList,
        ),
      };
    }
    default:
      return state;
  }
};
