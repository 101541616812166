/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fulfilled, rejected, clean, pending } from 'redux-saga-thunk';
import { currentDate, localDayjs as dayjs } from 'utils/dateTimeUtils';
import { ExceptionAvailabilityModal, Spinner } from 'components';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import { connect } from 'react-redux';
import { customEvent } from 'utils/pushNotificationUtils';

function addTimeToDate(datePicker, timePicker) {
  return `${datePicker.format('YYYY-MM-DD')} ${timePicker.format('HH:mm:ss')}`;
}

function ExceptionAvailabilityContainer({
  onClose,
  isVisible,
  editItem,
  onSubmitExceptionAvailability,
  onDeleteExceptionAvailability,
  isfulfilled,
  onAvailabilityGet,
  cleanExeceptionAvailabilityState,
  isPending,
}) {
  const [datePicker, setDatePicker] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);
  const [startTime, setStartTime] = useState(currentDate());
  const [endTime, setEndTime] = useState(currentDate().add(1, 'hours'));
  const [validationError, setValidationError] = useState({
    isValid: true,
    errorMessage: '',
  });
  const [isExistingAvailability, setIsExistingAvailability] = useState(false);

  useEffect(() => {
    if (editItem.selectedDate) {
      setDatePicker(editItem.selectedDate);
      setStartTime(editItem.startTime);
      setEndTime(editItem.startTime.add(1, 'hours'));
      setIsAvailable(editItem?.type !== 3);
      setIsExistingAvailability(editItem?.exceptionIdentifier || false);
    }
  }, [editItem]);

  useEffect(() => {
    if (isfulfilled) {
      onAvailabilityGet();
      onClose();
      cleanExeceptionAvailabilityState();
    }
  }, [isfulfilled]);
  const validateTheItem = (exceptionDatetimeFrom, exceptionDatetimeTo) => {
    const timedifferentInMin = dayjs
      .duration(
        dayjs(exceptionDatetimeTo, 'YYYY-MM-DD HH:mm:ss').diff(
          dayjs(exceptionDatetimeFrom, 'YYYY-MM-DD HH:mm:ss'),
        ),
      )
      .asMinutes();
    if (dayjs(exceptionDatetimeFrom, 'YYYY-MM-DD HH:mm:ss').diff(dayjs()) < 0) {
      setValidationError({
        isValid: false,
        errorMessage: '*Välj en framtida tidsram',
      });
      return false;
    }
    if (timedifferentInMin < 15 && timedifferentInMin >= 0) {
      setValidationError({
        isValid: false,
        errorMessage: '*Tidsramen bör vara minst 15 minuter',
      });
      return false;
    } else if (timedifferentInMin < 0) {
      setValidationError({
        isValid: false,
        errorMessage: '*Sluttiden måste vara högre än starttiden',
      });
      return false;
    }
    setValidationError({ isValid: true, errorMessage: '' });
    return true;
  };

  const handleOnSubmit = () => {
    const tempItem = {
      DatetimeFrom: addTimeToDate(datePicker, startTime),
      DatetimeTo: addTimeToDate(datePicker, endTime),
      IsAvailable: isAvailable,
      ExceptionIdentifier: isExistingAvailability
        ? editItem?.exceptionIdentifier
        : null,
    };
    if (
      dayjs(tempItem.DatetimeTo, 'YYYY-MM-DD HH:mm:ss').diff(
        dayjs(tempItem.DatetimeFrom, 'YYYY-MM-DD HH:mm:ss'),
      ) < 0
    ) {
      setEndTime(startTime);
      setStartTime(endTime);
    }
    if (validateTheItem(tempItem.DatetimeFrom, tempItem.DatetimeTo)) {
      onSubmitExceptionAvailability(tempItem);
    }
  };

  const handleDatePickerChange = date => {
    const changedDate = dayjs(date);
    //  validateTheItem(
    //     `${addTimeToDate(chnagedDate,startTime)}`,
    //     `${addTimeToDate(chnagedDate,endTime)}`,
    //   );
    setDatePicker(changedDate);
  };

  const handleAvailablityTypeChange = type => {
    setIsAvailable(type?.name !== 'occupied');
  };

  const handleTimePickerChange = (changedValue, typeKey) => {
    if (typeKey === 'start') {
      setStartTime(changedValue);
      validateTheItem(
        `${addTimeToDate(datePicker, changedValue)}`,
        `${addTimeToDate(datePicker, endTime)}`,
      );
    } else if (typeKey === 'end') {
      setEndTime(changedValue);
      validateTheItem(
        `${addTimeToDate(datePicker, startTime)}`,
        `${addTimeToDate(datePicker, changedValue)}`,
      );
    }
  };

  const handleDeleteException = () => {
    if (isExistingAvailability) {
      onDeleteExceptionAvailability({
        ExceptionIdentifier: editItem?.exceptionIdentifier,
      });
    }
  };

  return (
    <>
      <ExceptionAvailabilityModal
        isVisible={isVisible}
        onClose={onClose}
        validationError={validationError}
        selectedDate={datePicker ? datePicker.toDate() : datePicker}
        exceptionType={isAvailable ? 'available' : 'occupied'}
        startTime={startTime}
        endTime={endTime}
        showDelete={isExistingAvailability}
        onDelete={handleDeleteException}
        onConfirm={handleOnSubmit}
        onDatePickerChange={handleDatePickerChange}
        onTimePickerChange={handleTimePickerChange}
        onAvailablityTypeChange={handleAvailablityTypeChange}
      />
      {isPending && <Spinner />}
    </>
  );
}

ExceptionAvailabilityContainer.propTypes = {
  onClose: PropTypes.func,
  onSubmitExceptionAvailability: PropTypes.func,
  onDeleteExceptionAvailability: PropTypes.func,
  isVisible: PropTypes.bool,
  isfulfilled: PropTypes.bool,
  isPending: PropTypes.bool,
  editItem: PropTypes.shape({
    selectedDate: PropTypes.shape({}),
    startTime: PropTypes.shape({}),
  }),
};

ExceptionAvailabilityContainer.defaultProps = {
  onClose: () => {},
  onSubmitExceptionAvailability: () => {},
  onDeleteExceptionAvailability: () => {},
  isVisible: false,
  isfulfilled: false,
  isPending: false,
  editItem: {
    selectedDate: null,
    startTime: currentDate().startOf('hour').add(1, 'hour'),
  },
};

const mapDispatchToProps = dispatch => ({
  onSubmitExceptionAvailability: data =>
    dispatch(resourceCreateRequest('exceptionAvailabilityUpdate', data)),
  onDeleteExceptionAvailability: data =>
    dispatch(resourceCreateRequest('exceptionAvailabilityDelete', data)),
  cleanExeceptionAvailabilityState: () => {
    dispatch(clean('exceptionAvailabilityUpdateCreate'));
    dispatch(clean('exceptionAvailabilityDeleteCreate'));
  },
  onAvailabilityGet: () => {
    const params = { DatetimeFrom: new Date(), DatetimeTo: new Date() };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        true,
      ),
    );
  },
});
const mapStateToProps = state => ({
  isPending:
    pending(state, 'exceptionAvailabilityUpdateCreate') ||
    pending(state, 'exceptionAvailabilityDeleteCreate'),
  isfulfilled:
    fulfilled(state, 'exceptionAvailabilityUpdateCreate') ||
    fulfilled(state, 'exceptionAvailabilityDeleteCreate'),
  isfailed:
    rejected(state, 'exceptionAvailabilityUpdateCreate') ||
    rejected(state, 'exceptionAvailabilityDeleteCreate'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExceptionAvailabilityContainer);
