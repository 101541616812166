/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  resourceDetailReadRequest,
  transvoiceLogout,
  resourceListReadRequest,
  hideRatedAssignmentModal,
} from 'store/actions';
import {
  TopNavBar,
  MobileNavBar,
  UserFeedbackModal,
  MobileAppInfoModal,
} from 'components';
import { connect } from 'react-redux';
import { fromResource, fromWorkAssignment } from 'store/selectors';
import useWindowSize from 'hooks/useWindowSize';

import './style.scss';
import { currentDate, localDayjs as dayjs } from 'utils/dateTimeUtils';
import { fulfilled, clean } from 'redux-saga-thunk';
import {
  customEvent,
  loadAirShipModules,
  registerAirshipChannel,
  unregisterAirShipChannel,
} from 'utils/pushNotificationUtils';
import {
  LOCAL_STORE_INTERPRETER_NUMBER,
  LOCAL_STORE_MOBILE_BANNER_SEEN_TIME,
} from 'constant';
import { useHistory } from 'react-router-dom';
import { createUpdateWebSession } from 'utils/browserUtils';
import { sleepTime } from 'utils/miscUtil';

const MainLayout = ({
  children,
  getInterpreterProfile,
  isProfileLoaded,
  cleanState,
  profile,
  onLogout,
  getResourceAvailability,
  getInterpreterRequests,
  getAllNewNotifications,
  getSemesterAllocations,
  getRatedWorkAssignments,
  ratedAssignmentDetail,
  isRatedAssignment,
  onHideRatedAssignmentModal,
}) => {
  const { isMobileView, userAgent } = useWindowSize();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [showRatedAssignment, setShowRatedAssignment] = useState(false);
  const [showMobileAppInfo, setShowMobileAppInfo] = useState(false);
  const [isShownCorrectTime, setIsShownCorrectTime] = useState(false);

  useEffect(async () => {
    if (isProfileLoaded) {
      const interpreterNumber = localStorage.getItem(
        LOCAL_STORE_INTERPRETER_NUMBER,
      );
      if (interpreterNumber !== null) {
        cleanState('userProfileDetailRead');

        await createUpdateWebSession(interpreterNumber);
      }
    }
  }, [isProfileLoaded]);

  function checkBannerShownInCorrectTime() {
    const submittedTime = localStorage.getItem(
      LOCAL_STORE_MOBILE_BANNER_SEEN_TIME,
    );
    const submittedTimeInUTC = dayjs.utc(submittedTime);

    const currentDateTime = dayjs.utc();

    const startOfWeek = currentDateTime.startOf('week');
    const endOfWeek = currentDateTime.endOf('week');

    const bannerShowingTime = startOfWeek.add(5, 'hours').add(30, 'minutes');

    if (submittedTime) {
      if (!submittedTimeInUTC.isBetween(startOfWeek, endOfWeek, null, '[]')) {
        if (
          !currentDateTime.isBetween(startOfWeek, bannerShowingTime, null, '[]')
        ) {
          setIsShownCorrectTime(true);
        }
      }
    } else {
      setIsShownCorrectTime(true);
    }
  }

  useEffect(async () => {
    await getInterpreterProfile();
    await getResourceAvailability();
    await getInterpreterRequests();
    await getSemesterAllocations();
    await getRatedWorkAssignments();
    checkBannerShownInCorrectTime();
    const getRequestIntervalHandle = setInterval(() => {
      getInterpreterRequests();
      getAllNewNotifications(!isMobileView);
      checkBannerShownInCorrectTime();
    }, 600000);
    return () => {
      clearInterval(getRequestIntervalHandle);
      // unregisterAirShipChannel();
    };
  }, []);

  useEffect(() => {
    getAllNewNotifications(!isMobileView);
  }, [isMobileView]);

  useEffect(() => {
    if (isMobileView) {
      setShowMobileAppInfo(true);
    } else {
      setShowMobileAppInfo(false);
    }
  }, [isMobileView]);

  const handleLogout = async () => {
    const interpreterNumber = localStorage.getItem(
      LOCAL_STORE_INTERPRETER_NUMBER,
    );

    const list = await navigator.serviceWorker.getRegistrations();
    sleepTime(1000);
    if (list?.length > 0) {
      await unregisterAirShipChannel(interpreterNumber).then(() => {
        history.push('/login');
        onLogout();
      });
    } else {
      history.push('/login');
      onLogout();
    }
  };

  useEffect(() => {
    if (isRatedAssignment) {
      setShowRatedAssignment(true);
    }
  }, [ratedAssignmentDetail]);

  const getMobilePlatform = () => {
    const isAndroid = /Android/i.test(userAgent);
    const isIos = /iPhone/i.test(userAgent);

    if (isAndroid) {
      return 'android';
    }
    if (isIos) {
      return 'ios';
    }

    return null;
  };

  const getMobileAppImagePath = () => {
    const platform = getMobilePlatform();

    if (platform === 'android') {
      return '/images/google-play-badge.png';
    }
    if (platform === 'ios') {
      return '/images/apple-store-badge.svg';
    }
    return null;
  };

  const generateMobileAppLink = () => {
    const platform = getMobilePlatform();
    switch (platform) {
      case 'android':
        return 'https://play.google.com/store/apps/details?id=se.transvoice.tolka';
      case 'ios':
        return 'https://apps.apple.com/us/app/tolka/id1489136873';
      default:
        return '';
    }
  };

  return (
    <>
      {!isMobileView ? (
        <TopNavBar
          customerName={
            profile.FirstName
              ? `${profile.FirstName} ${profile.LastName}`
              : null
          }
          onLogout={handleLogout}
        />
      ) : (
        <MobileNavBar
          visible={visible}
          setVisible={setVisible}
          onLogout={handleLogout}
          customerName={
            profile.FirstName
              ? `${profile.FirstName} ${profile.LastName}`
              : null
          }
        />
      )}
      <div className={`tv-main_layout__container ${visible ? 'd-none' : ''}`}>
        {children}
        {showMobileAppInfo && isShownCorrectTime && (
          <MobileAppInfoModal
            isVisible
            onClose={() => {
              setShowMobileAppInfo(false);
              localStorage.setItem(
                LOCAL_STORE_MOBILE_BANNER_SEEN_TIME,
                dayjs.utc().format('YYYY-MM-DD HH:mm'),
              );
            }}
            imagePath={getMobileAppImagePath()}
            linkPath={generateMobileAppLink()}
          />
        )}
        {showRatedAssignment && (
          <UserFeedbackModal
            isVisible
            onClick={() => {
              setShowRatedAssignment(false);
              onHideRatedAssignmentModal();
            }}
            onClose={null}
            onOverlayClick={null}
            item={ratedAssignmentDetail}
          />
        )}
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  getInterpreterProfile: PropTypes.func,
  profile: PropTypes.shape({}),
  onLogout: PropTypes.func,
  getRatedWorkAssignments: PropTypes.func,
  onHideRatedAssignmentModal: PropTypes.func,
};
MainLayout.defaultProps = {
  children: null,
  profile: {},
  getInterpreterProfile: () => {},
  onLogout: () => {},
  getRatedWorkAssignments: () => {},
  onHideRatedAssignmentModal: () => {},
};
const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const mapDispatchToProps = dispatch => ({
  getInterpreterProfile: () => {
    dispatch(resourceDetailReadRequest('userProfile'));
  },
  getRatedWorkAssignments: () => {
    dispatch(resourceDetailReadRequest('ratedWorkAssignments'));
  },
  cleanState: thunk => {
    dispatch(clean(thunk));
  },
  getResourceAvailability: () => {
    const params = {
      DatetimeFrom: currentDate().format(STANDARD_DATE_TIME_FORMAT),
      DatetimeTo: currentDate().format(STANDARD_DATE_TIME_FORMAT),
    };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        true,
      ),
    );
  },
  onLogout: () => {
    dispatch(transvoiceLogout());
  },
  getSemesterAllocations: () => {
    // const date = dayjs(new Date()).format('YYYY-MM-DD');
    const params = {};
    dispatch(
      resourceListReadRequest(
        'semesterAllocations',
        'VacationDays',
        params,
        true,
      ),
    );
  },
  getInterpreterRequests: () => {
    dispatch(
      resourceListReadRequest('assignmentRequest', 'ResourceRequests', {
        ResourceRequestStatus: 2,
      }),
      true,
    );
  },
  getAllNewNotifications: DoOnlyIncludeUnreadNotifications => {
    dispatch(
      resourceDetailReadRequest('searchAllNotifications', {
        DatetimeSentFrom: currentDate()
          .subtract(3, 'days')
          .format('YYYY-MM-DD'),
        DoOnlyIncludeUnreadNotifications: false,
        OrderIdentifier: null,
        WorkAssignmentIdentifier: null,
      }),
      true,
    );
  },
  onHideRatedAssignmentModal: () => {
    dispatch(hideRatedAssignmentModal());
  },
});
const mapStateToProps = state => {
  const user = fromResource.getDetail(state, 'userProfile') || {};
  const ratedAssignment = fromWorkAssignment.getRatedAssignment(state);
  return {
    profile: user.Profile,
    isProfileLoaded: fulfilled(state, 'userProfileDetailRead'),
    ratedAssignmentDetail: ratedAssignment.ratedAssignment,
    isRatedAssignment: ratedAssignment.isRatedAssignment,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
