const merge = require('lodash/merge');

const config = {
  all: {
    env: process.env.REACT_APP_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_PATH,
    isBrowser: typeof window !== 'undefined',
    apiUrl:
      process.env.REACT_APP_MULTISOFT_API ||
      'http://localhost:3000/api/WebServices/CRM/',
    apiUrlCommon:
      process.env.REACT_APP_MULTISOFT_API_COMMON ||
      'http://localhost:3000/api/WebServices/Common/',
    apiUrlCRMCustomer:
      process.env.REACT_APP_MULTISOFT_API_CRM_CUSTOMER ||
      'http://localhost:3000/api/WebServices/CRMCustomer/',
    multiSoftApiKey:
      process.env.REACT_APP_MULTISOFT_API_KEY ||
      'CE0C9A69-1707-4C92-BE2F-8935188A6FEB',
    mutiSoftCryptoKey:
      process.env.REACT_APP_MULTISOFT_CRYPTO_KEY ||
      '873080AA-C50F-4D36-8549-C5734BA6854B',
    pushNotificationAppKey:
      process.env.REACT_APP_PUSH_NOTIFICATION_APP_KEY ||
      'SDEykmPFTJ6VLQLi0sK4ig',
    pushNotificationToken:
      process.env.REACT_APP_PUSH_NOTIFICATION_TOKEN ||
      'MTpTREV5a21QRlRKNlZMUUxpMHNLNGlnOkZQR2NkMjRzOW0yeTZsTmNkVk1pem9pbGhVZW1ZZmhJV0FJUUZNSGZKLTA',
    pushNotificationVapidPublicKey:
      process.env.REACT_APP_PUSH_NOTIFICATION_VPKEY ||
      'BAJHK_cRIRmBp_ryq-yFkb4hgC28nyREnSBlQy4fex1tUo-DuSg7f_4Zf5i54gqQdx-12bmTyxRongKVwv0aLMA=',
    pushNotificationWebPushId:
      process.env.REACT_APP_PUSH_NOTIFICATION_WEBPUSHID ||
      'web.se.transvoice.tolkqa',
    clarityProjectKey:
      process.env.REACT_APP_CLARITY_INTEGRATION_PROJECT_KEY || 'ih16j1ntio',
    currentVersion: process.env.REACT_APP_VERSION || 'Version : 2.4.0',
  },
  development: {
    logApi: '',
  },
  qa: {
    logApi: 'http://80.72.14.253/api/Log/',
  },
  staging: {
    logApi: 'http://80.72.14.253/api/Log/',
  },
  production: {
    logApi: 'https://msg.transvoice.se/api/Log/',
  },
};

module.exports = merge(config.all, config[config.all.env]);
